import {
  addMinutes,
  addMilliseconds,
  differenceInMinutes,
  differenceInMilliseconds,
  format,
  isAfter,
  parse,
  isValid
} from 'date-fns';

/**
 * Combina la fecha y la hora en un solo objeto Date
 * @param {Date} date La fecha a combinar
 * @param {Date} combineTime La hora a combinar
 * @returns Un objeto Date con la fecha y la hora combinadas
 */
export function combineDateAndTime(date: Date, time: Date): Date {
  const [combineDate, combineTime] = [new Date(date), new Date(time)];
  return new Date(
    combineDate.getFullYear(),
    combineDate.getMonth(),
    combineDate.getDate(),
    combineTime.getHours(),
    combineTime.getMinutes()
  );
}

/**
 * Construye un array de segmentos de tiempo a partir de un límite inferior y superior y un tamaño de segmento en mintos
 * @param {Date} limInf Límite inferior
 * @param {Date} limSup Límite superior
 * @param {number} segmentMinutesSize Tamaño de cada segmento en minutos
 * @returns {Date[]} Un array de segmentos de tiempo
 */
export function createDateSegments(
  limInf: Date,
  limSup: Date,
  segmentMinutesSize: number
): Date[] {
  const segments: Date[] = [];
  if (isAfter(limInf, limSup)) {
    return segments;
  }
  let current = new Date(limInf);
  let next = new Date(current);
  next.setMinutes(current.getMinutes() + segmentMinutesSize);
  segments.push(current);
  const currentSup = new Date(limSup);
  while (next <= currentSup) {
    segments.push(next);
    current = next;
    next = new Date(current);
    next.setMinutes(current.getMinutes() + segmentMinutesSize);
  }

  return segments;
}

/**
 * Formatea una fecha en un string con formato 24 horas
 * @param {Date} time fecha a convertir
 * @returns {string} hora en formato HH:mm
 */
export function dateToStringTime(date: Date): string {
  return format(date, 'HH:mm');
}

/**
 * Crea una fecha con base en los milisegundos
 * @param {number} mills Los milisegundos
 * @returns {Date} La fecha creada
 */
export function fromMills(mills: number): Date {
  return new Date(mills);
}

/**
 * Obtiene la diferencia en minutos entre dos fechas
 * @param {Date} date1 Fecha 1
 * @param {Date} date2 Fecha 2
 * @returns {number} Diferencia en minutos
 */
export function getMinutesDifference(date1: Date, date2: Date): number {
  return differenceInMinutes(date1, date2);
}

/**
 * Obtiene la diferencia en Milliseconds entre dos fechas
 * @param {Date} date1 Fecha 1
 * @param {Date} date2 Fecha 2
 * @returns {number} Diferencia en Milliseconds
 */
export function getMillisecondsDifference(date1: Date, date2: Date): number {
  return differenceInMilliseconds(date1, date2);
}

/**
 * Convierte un string a un objeto Date
 * @param {string} date string a convertir en formato YYYY-MM-DD
 * @returns Un objeto Date
 */
export function stringToDate(date: string): Date {
  const [year, month, day] = date.split('-').map((part) => parseInt(part, 10));
  return new Date(year, month - 1, day);
}

/**
 * Convierte un string a un objeto Date con hora
 * @param date string a convertir en formato YYYY-MM-DD
 * @param time objeto Date que contiene la hora
 * @returns Un objeto Date con la fecha y la hora combinadas
 */
export function stringToDateWithTime(date: string | Date, time: Date): Date {
  const parsedDate =
    typeof date === 'string'
      ? date.split('-').map((part) => parseInt(part, 10))
      : [date.getFullYear(), date.getMonth() + 1, date.getDate()];
  const [year, month, day] = parsedDate;
  return new Date(year, month - 1, day, time.getHours(), time.getMinutes());
}

/**
 * Convierte un objeto Date a una cadena de texto en formato 'YYYY-MM-DD'.
 *
 * @param date - El objeto Date que se desea convertir a cadena.
 * @returns La fecha formateada como una cadena en formato 'YYYY-MM-DD'.
 */
export function dateToString(date: Date): string {
  return format(date, 'yyyy-MM-dd');
}

/**
 * Convierte un objeto Date a una cadena de texto en formato 'YYYY-MM-DD HH:mm'.
 *
 * @param {Date} date - El objeto Date que se desea convertir a cadena.
 * @returns {string} La fecha formateada
 */
export function dateWithTimeToString(date: Date): string {
  return format(date, 'yyyy-MM-dd HH:mm');
}

/**
 * Agrega minutos a una fecha
 * @param {Date} date La fecha a la que se le agregarán minutos
 * @param {number} minutes La cantidad de minutos a agregar
 * @returns {Date} La nueva fecha con minutos agregados
 */
export function dateAddMinutes(date: Date, minutes: number): Date {
  return addMinutes(date, minutes);
}

/**
 * Valida si un string cumple con un formato de fecha específico y devuelve un objeto Date si es válido.
 *
 * @param dateString El string que representa la fecha.
 * @param formatString El formato esperado del string (ej: 'yyyy-MM-dd', 'dd/MM/yyyy HH:mm').
 * @returns Un objeto Date si el string es válido y cumple con el formato, o null si no lo es.
 */
export function parseDateFromString(
  dateString: string,
  formatString: string
): Date | null {
  try {
    const parsedDate = parse(dateString, formatString, new Date());
    if (isValid(parsedDate)) {
      return parsedDate;
    } else {
      return null;
    }
  } catch {
    return null;
  }
}

/**
 * Agrega millisegundos a una fecha
 * @param {Date} date La fecha a la que se le agregarán millisegundos
 * @param {number} milliseconds La cantidad de millisegundos a agregar
 * @returns {Date} La nueva fecha con millisegundos agregados
 */
export function dateAddMilliseconds(date: Date, milliseconds: number): Date {
  return addMilliseconds(date, milliseconds);
}
